var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { from } from "rxjs";
import { AjaxService, HTML_RESPONSE_TYPE } from "../common/ajax";
import { FirebaseAuthHandler } from "./firebase/firebaseAuthHandler";
import { exhaustMap } from "rxjs/operators";
import { FirebaseSocialAuthHandler } from "./firebase/firebaseSocialAuthHandler";
var LOGIN_TIMEOUT = 100000;
var LOGIN_URL = "/orion/ajax/account/login-check";
var LOGIN_URL_WITH_ACCOUNT = "/orion/ajax/account/login-check-with-account";
var LOGOUT_URL = "/logout";
var PARTNER_LOGIN_CLASS = ".partner-login";
var TAP_SIGNUP_CLASS = ".tap-signup";
var TAP_SIGN_UP_API = "/orion/ajax/signup/submit";
var SOCIAL_AUTH_REASON_MAP = {
    APPLE: "Apple",
    FACEBOOK: "Facebook",
    GOOGLE: "Google",
    GOOGLE_ONE_TAP: "GoogleOneTap",
};
var SocialAuthProvider;
(function (SocialAuthProvider) {
    SocialAuthProvider["APPLE"] = "apple.com";
    SocialAuthProvider["FACEBOOK"] = "facebook.com";
    SocialAuthProvider["GOOGLE"] = "google.com";
    SocialAuthProvider["GOOGLE_ONE_TAP"] = "onetap.google.com";
})(SocialAuthProvider || (SocialAuthProvider = {}));
var LOGIN_ERROR = {
    AGENT_ARCHIVED: "AGENT_ARCHIVED",
    AGENT_ROLE_MISSING: "AGENT_ROLE_MISSING",
    AFFILIATE_ROLE_MISSING: "AFFILIATE_ROLE_MISSING",
    BAD_CREDENTIAL: "BAD_CREDENTIAL",
    USER_PROFILE_NOT_ACTIVE: "USER_PROFILE_NOT_ACTIVE",
    GENERIC_ERROR: "GENERIC_ERROR"
};
var SIGNUP_ERROR = {
    EMAIL_EXISTS: "EMAIL_EXISTS",
    PASSWORD_INVALID: "PASSWORD_INVALID",
    PASSWORD_NOT_MATCH: "PASSWORD_NOT_MATCH",
    UNKNOWN: "UNKNOWN"
};
var RESET_PASSWORD_CUSTOM_ERROR = {
    PASSWORD_INVALID: "PASSWORD_INVALID",
    TOKEN_INVALID: "TOKEN_INVALID",
    PASSWORDS_DO_NOT_MATCH: "PASSWORDS_DO_NOT_MATCH",
    UNKNOWN: "UNKNOWN"
};
var UPDATE_PASSWORD_RESULT = {
    SUCCESS: "SUCCESS",
    PASSWORD_INCORRECT: "PASSWORD_INCORRECT",
    PASSWORD_INVALID: "PASSWORD_INVALID",
    TOKEN_INVALID: "TOKEN_INVALID",
    PASSWORDS_DO_NOT_MATCH: "PASSWORDS_DO_NOT_MATCH",
    PASSWORD_IS_NOT_DIFFERENT: "PASSWORD_IS_NOT_DIFFERENT",
    NO_SUCH_ACCOUNT: "NO_SUCH_ACCOUNT",
    UNKNOWN: "UNKNOWN"
};
var UPDATE_EMAIL_RESULT = {
    SUCCESS: "SUCCESS",
    ACCOUNT_EMAIL_ADDRESS_NOT_UNIQUE: "ACCOUNT_EMAIL_ADDRESS_NOT_UNIQUE",
    NO_SUCH_ACCOUNT: "NO_SUCH_ACCOUNT",
    PASSWORD_INCORRECT: "PASSWORD_INCORRECT",
    UNKNOWN: "UNKNOWN"
};
var LOG_LEVEL = {
    ERROR: "ERROR",
    WARN: "WARN",
    INFO: "INFO",
    DEBUG: "DEBUG"
};
var doLogin = function (ajax, loginForm, request, withAccount) {
    var ajaxService = ajax || new AjaxService();
    var ajaxResponse;
    if (loginForm.find(PARTNER_LOGIN_CLASS).length) {
        var postBody = __assign({}, request, { _csrf: request.csrf });
        ajaxResponse = ajaxService.doPostWithTimeout(withAccount ? LOGIN_URL_WITH_ACCOUNT : LOGIN_URL, LOGIN_TIMEOUT, postBody, HTML_RESPONSE_TYPE, { "Content-Type": "application/json" });
    }
    else {
        var firebaseAuthHandler = new FirebaseAuthHandler(ajaxService);
        return firebaseAuthHandler.signInWithPassword(request.email, request.password, LOGIN_TIMEOUT, request.csrf);
    }
    return ajaxResponse;
};
var doLoginWithCustomToken = function (ajax, customToken) {
    var ajaxService = ajax || new AjaxService();
    var firebaseAuthHandler = new FirebaseAuthHandler(ajaxService);
    return firebaseAuthHandler.signInWithCustomToken(customToken);
};
var signInOrSignUpWithGoogleOneTapToken = function (ajax, oneTapIdToken, isSubscribe) {
    var ajaxService = ajax || new AjaxService();
    var firebaseSocialAuthHandler = new FirebaseSocialAuthHandler(ajaxService);
    return firebaseSocialAuthHandler.signInOrSignUpWithGoogleOneTapToken(oneTapIdToken, isSubscribe);
};
var doLogout = function (ajax) {
    var ajaxService = ajax || new AjaxService();
    var firebaseAuthHandler = new FirebaseAuthHandler(ajaxService);
    return firebaseAuthHandler.signOut().pipe(exhaustMap(function () { return ajaxService.doGetSafe(LOGOUT_URL); }));
};
var doSignup = function (ajax, signupForm, request, timeout) {
    var ajaxService = ajax || new AjaxService();
    var ajaxResponse;
    if (signupForm.find(TAP_SIGNUP_CLASS).length) {
        ajaxResponse = ajaxService.doPostWithTimeout(TAP_SIGN_UP_API, timeout, request, HTML_RESPONSE_TYPE);
    }
    else {
        var firebaseAuthHandler = new FirebaseAuthHandler(ajaxService);
        var firebaseSignUpRequest = {
            email: request.email,
            password: request.password,
            firstName: request.firstName || "",
            lastName: request.lastName || "",
            subscribe: request.subscribe === "on"
        };
        ajaxResponse = firebaseAuthHandler.signupWithPassword(firebaseSignUpRequest, timeout);
    }
    return ajaxResponse;
};
var doSocialLogin = function (provider, csrf, isSubscribe) {
    var firebaseSocialAuthHandler = new FirebaseSocialAuthHandler();
    return firebaseSocialAuthHandler.signInWithSocial(provider, csrf, isSubscribe);
};
var handleSocialAuthRedirectResult = function () {
    var firebaseSocialAuthHandler = new FirebaseSocialAuthHandler();
    return firebaseSocialAuthHandler.handleRedirectResult();
};
var doAddPassword = function (ajax, password) {
    var ajaxService = ajax || new AjaxService();
    var firebaseAuthHandler = new FirebaseAuthHandler(ajaxService);
    return firebaseAuthHandler.addPassword(password);
};
var doUpdatePassword = function (ajax, currentPassword, newPassword, confirmPassword) {
    if (currentPassword === newPassword) {
        return from(Promise.reject(UPDATE_PASSWORD_RESULT.PASSWORD_IS_NOT_DIFFERENT));
    }
    else if (newPassword !== confirmPassword) {
        return from(Promise.reject(UPDATE_PASSWORD_RESULT.PASSWORDS_DO_NOT_MATCH));
    }
    var ajaxService = ajax || new AjaxService();
    return new FirebaseAuthHandler(ajaxService).updatePassword(currentPassword, newPassword);
};
var doUpdateEmail = function (ajax, currentPassword, newEmail) {
    var ajaxService = ajax || new AjaxService();
    return new FirebaseAuthHandler(ajaxService).updateEmail(currentPassword, newEmail);
};
var doPasswordReset = function (oobCode, email, newPassword) {
    var firebaseAuthHandler = new FirebaseAuthHandler();
    return firebaseAuthHandler.confirmPasswordReset(oobCode, email, newPassword);
};
var doLogMessage = function (logAction, logMessage) {
    var firebaseAuthHandler = new FirebaseAuthHandler();
    firebaseAuthHandler.logMessage(logAction, logMessage);
};
export { doLogin, doLoginWithCustomToken, signInOrSignUpWithGoogleOneTapToken, doLogout, doSignup, doSocialLogin, doAddPassword, doUpdatePassword, doUpdateEmail, doPasswordReset, doLogMessage, handleSocialAuthRedirectResult, SocialAuthProvider, SOCIAL_AUTH_REASON_MAP, LOGIN_ERROR, SIGNUP_ERROR, RESET_PASSWORD_CUSTOM_ERROR, UPDATE_PASSWORD_RESULT, UPDATE_EMAIL_RESULT, LOG_LEVEL };
