import { AjaxService, HTML_RESPONSE_TYPE } from "./common/ajax";
import { fromEvent as observableFromEvent, of as observableOf } from "rxjs";
import { catchError, tap } from "rxjs/operators";
var EmailSubscription = (function () {
    function EmailSubscription(window, emailSubscriptionModal) {
        this.ajaxService = AjaxService.getInstance();
        this.window = window;
        this.document = window.document;
        this.emailSubscriptionModal = emailSubscriptionModal;
    }
    EmailSubscription.prototype.startEmailSubscriptionModalVerification = function () {
        var _this = this;
        this.ajaxService.doGet(EmailSubscription.EMAIL_SUBSCRIPTION_ENDPOINT)
            .pipe(catchError(function (error) {
            return observableOf(error);
        }), tap(function (response) {
            if (response.status === 200) {
                _this.showEmailSubscriptionModal(response);
            }
        })).subscribe();
    };
    EmailSubscription.prototype.renderModalContentAfterAccountCreation = function (provider) {
        if (this.allowToShowEmailSubscriptionCheckBox) {
            this.provider = provider;
            this.showEmailSubscriptionCheckbox();
        }
        else {
            this.showAccountCreated();
        }
    };
    EmailSubscription.prototype.showEmailSubscriptionModal = function (response) {
        this.emailSubscriptionModal.html(response.response);
        this.handleSubscribe();
        this.emailSubscriptionModal.find(".modal-dialog").removeClass("d-none");
        this.emailSubscriptionModal.find(".loading-element").removeClass("d-none");
        this.emailSubscriptionModal.find("#almostThereHeader").removeClass("d-none");
        this.emailSubscriptionModal.modal("show");
        this.allowToShowEmailSubscriptionCheckBox = this.emailSubscriptionModal
            .find("#emailSubscriptionForm")
            .attr("data-show-email-subscription-modal") === "true";
    };
    EmailSubscription.prototype.handleSubscribe = function () {
        var _this = this;
        observableFromEvent(this.emailSubscriptionModal.find(".accept"), "click")
            .pipe(tap(function () { return _this.prepareSubscribing(); }), tap(function () { return _this.subscribe(); })).subscribe();
    };
    EmailSubscription.prototype.showEmailSubscriptionCheckbox = function () {
        this.emailSubscriptionModal.find(".loading-element").addClass("d-none");
        this.emailSubscriptionModal.find(".non-loading").removeClass("d-none");
    };
    EmailSubscription.prototype.showAccountCreated = function () {
        this.emailSubscriptionModal.find(".loading-element").addClass("d-none");
        this.emailSubscriptionModal.find(".accept").addClass("d-none");
        this.emailSubscriptionModal.find(".success-text").removeClass("d-none");
        this.emailSubscriptionModal.find("#almostThereHeader").addClass("d-none");
        this.emailSubscriptionModal.find("#welcomeHeader").removeClass("d-none");
        window.location.reload();
    };
    EmailSubscription.prototype.showAccountSubscribed = function () {
        this.emailSubscriptionModal.find(".success-text").addClass("d-none");
        this.emailSubscriptionModal.find(".loading-element").addClass("d-none");
        this.emailSubscriptionModal.find(".loading-subscribing").addClass("d-none");
        this.emailSubscriptionModal.find(".non-loading").addClass("d-none");
        this.emailSubscriptionModal.find(".button-container").addClass("d-none");
        this.emailSubscriptionModal.find(".success-subscribing").removeClass("d-none");
        this.emailSubscriptionModal.find("#almostThereHeader").addClass("d-none");
        this.emailSubscriptionModal.find("#welcomeHeader").removeClass("d-none");
        window.location.reload();
    };
    EmailSubscription.prototype.prepareSubscribing = function () {
        this.emailSubscriptionModal.find(".loading-element").removeClass("d-none");
        this.emailSubscriptionModal.find(".loading-subscribing").removeClass("d-none");
        this.emailSubscriptionModal.find(".loading-message").addClass("d-none");
        this.emailSubscriptionModal.find(".non-loading").addClass("d-none");
    };
    EmailSubscription.prototype.subscribe = function () {
        var _this = this;
        var shouldSubscribe = this.emailSubscriptionModal.find("#emailSubscriptionCheckbox")
            .is(":checked");
        var provider = this.provider;
        var subscribe = true;
        if (shouldSubscribe) {
            this.ajaxService.postJSON(EmailSubscription.EMAIL_SUBSCRIPTION_ENDPOINT, { provider: provider, subscribe: subscribe }, HTML_RESPONSE_TYPE)
                .pipe(catchError(function (error) {
                return observableOf(error);
            }), tap(function (response) {
                _this.showAccountSubscribed();
            }), tap(function () { return _this.window.location.reload(); })).subscribe();
        }
        else {
            this.emailSubscriptionModal.find(".modal-dialog").addClass("d-none");
            this.emailSubscriptionModal.find(".loading-element").addClass("d-none");
            this.emailSubscriptionModal.modal("hide");
            window.location.reload();
        }
    };
    EmailSubscription.EMAIL_SUBSCRIPTION_ENDPOINT = "/orion/ajax/email/subscription";
    return EmailSubscription;
}());
export { EmailSubscription };
