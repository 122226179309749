import $ from "../../common/jquery";
var LoginModalSuccessHandler = (function () {
    function LoginModalSuccessHandler($window, $container) {
        if ($window === void 0) { $window = window; }
        if ($container === void 0) { $container = $("body"); }
        var _this = this;
        this.fromBookableLink = false;
        this.fromCheckoutBtn = false;
        this.window = $window;
        this.$container = $container;
        this.$container.find(LoginModalSuccessHandler.TAP_SELECTORS).click(function () { return _this.fromBookableLink = true; });
        this.$container.find(LoginModalSuccessHandler.TAP_CHECKOUT).click(function () { return _this.fromCheckoutBtn = true; });
        this.$container.find("#close-button").click(function () { return _this.fromBookableLink = false; });
        this.$container.find("#forgotPasswordButton").click(function () { return _this.handleForgotPassword(); });
        this.$container.find(".forgotPasswordLink").click(function () { return _this.handleForgotPassword(); });
    }
    LoginModalSuccessHandler.prototype.handleSuccess = function (response) {
        this.$container.find("#loginModal").modal("hide");
        var searchParams = new URLSearchParams(window.location.search);
        var redirectUrl = searchParams.get(LoginModalSuccessHandler.REDIRECT_URL_PARAM);
        if (this.fromBookableLink) {
            this.window.location.replace(this.appendBookableLinkModalToWindowUrl());
        }
        else if (this.fromCheckoutBtn) {
            this.window.location.replace(this.goToCheckoutPage());
        }
        else if (redirectUrl) {
            this.window.location.replace(this.appendRedirectUrl(redirectUrl));
        }
        else if (this.window.location.href && this.window.location.href.indexOf("?") > -1) {
            this.window.location.href = this.removeLoginModalParameter(new URL(this.window.location.href));
        }
        else {
            this.window.location.reload();
        }
    };
    LoginModalSuccessHandler.prototype.handleForgotPassword = function () {
        this.$container.find("#tapLoginModal").modal("hide");
        this.$container.find("#tapForgotPasswordModal").modal("show");
    };
    LoginModalSuccessHandler.prototype.appendBookableLinkModalToWindowUrl = function () {
        if (this.window.location.href && this.window.location.href.indexOf("?") > -1) {
            return this.window.location.href + "&bookableLinkModal=1";
        }
        else {
            return this.window.location.href + "?bookableLinkModal=1";
        }
    };
    LoginModalSuccessHandler.prototype.goToCheckoutPage = function () {
        return this.window.location.origin + "/cart/checkout";
    };
    LoginModalSuccessHandler.prototype.removeLoginModalParameter = function (url) {
        url.searchParams.delete(LoginModalSuccessHandler.LOGIN_MODAL_URL_PARAM);
        return url.href;
    };
    LoginModalSuccessHandler.prototype.appendRedirectUrl = function (redirectUrl) {
        return this.window.location.origin + redirectUrl;
    };
    LoginModalSuccessHandler.TAP_SELECTORS = "#tapShareLinkLogin, #tapShareLinkHomeHeaderLogin, #tapShareLinkHomeSideNavLogin";
    LoginModalSuccessHandler.TAP_CHECKOUT = "#checkoutBtn";
    LoginModalSuccessHandler.LOGIN_MODAL_URL_PARAM = "loginModal";
    LoginModalSuccessHandler.REDIRECT_URL_PARAM = "login_redirect";
    return LoginModalSuccessHandler;
}());
export { LoginModalSuccessHandler };
