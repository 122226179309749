import { EMPTY, fromEvent as observableFromEvent, Subject } from "rxjs";
import { catchError, exhaustMap, filter, tap } from "rxjs/operators";
import "bootstrap";
import "parsleyjs";
import { LoginFormValidator } from "../tap/loginFormValidator";
import { AjaxService } from "../common/ajax";
import { ClickStream } from "../clickstream";
import $ from "../common/jquery";
import { AjaxError, AjaxTimeoutError } from "rxjs/ajax";
import { FormStatus } from "./IFormResponse";
import { HttpCodes } from "../common/HttpCodes";
import { ShowHidePassword } from "./showHidePassword";
import { doLogMessage, doLogin, LOGIN_ERROR } from "./authUtil";
var LoginForm = (function () {
    function LoginForm($container, ajaxService) {
        if (ajaxService === void 0) { ajaxService = new AjaxService(); }
        this.loginSubject = new Subject();
        this.$container = $container;
        this.ajaxService = ajaxService;
    }
    LoginForm.prototype.getFormObservable = function () {
        return this.loginSubject;
    };
    LoginForm.prototype.initialize = function () {
        var _this = this;
        this.loginForm = this.$container.find(LoginForm.LOGIN_FORM_CLASS);
        this.setupEnterKeyToLogin($(this.loginForm));
        this.validator = new LoginFormValidator(this.loginForm);
        this.loginButton = $(this.loginForm).find(LoginForm.LOGIN_BUTTON_CLASS);
        this.partnerLoginButton = $(this.loginForm).find(LoginForm.PARTNER_LOGIN_BUTTON_CLASS);
        this.buildStream$().subscribe(function (response) { return _this.postClickStreamLoginSuccess(response); });
        this.subscribeToPartnerLoginButtonClick();
        var showHidePassword = this.$container.find(ShowHidePassword.SHOW_HIDE_BTN_CLASS);
        if (showHidePassword.length) {
            new ShowHidePassword(showHidePassword);
        }
        return this;
    };
    LoginForm.prototype.postClickStreamLoginSuccess = function (response) {
        var _this = this;
        ClickStream.postClickStreamWithCallback({
            pageAction: "login_success",
            servletName: LoginForm.LOGIN_MODAL_TRACKING_SERVLET
        }, function () {
            _this.loginSubject.next({ status: FormStatus.SUCCESS, message: response.responseText });
        });
    };
    LoginForm.prototype.buildAjaxPost$ = function () {
        var email = this.loginForm.find("input[name='email']")[0].value;
        var password = this.loginForm.find("input[name='password']")[0].value;
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: "click_account_modal_login",
            servletName: LoginForm.LOGIN_MODAL_TRACKING_SERVLET
        });
        this.addLoadingBubbles();
        this.hideErrorBanners();
        this.loginSubject.next({ status: FormStatus.SUBMITTED });
        return doLogin(this.ajaxService, this.loginForm, { email: email, password: password }, false);
    };
    LoginForm.prototype.setupEnterKeyToLogin = function ($loginForm) {
        var _this = this;
        observableFromEvent($loginForm, "keydown")
            .pipe(filter(function (e) { return e.key === LoginForm.KEY_ENTER; }))
            .subscribe(function () {
            $loginForm.find(LoginForm.LOGIN_BUTTON_CLASS).trigger("click");
            _this.partnerLoginButton.trigger("click");
        });
    };
    LoginForm.prototype.validateForm = function () {
        return this.loginForm.parsley().validate();
    };
    LoginForm.prototype.isWishlistModal = function () {
        return $(this.loginForm).parents("#wishlistLoginForm").length > 0;
    };
    LoginForm.prototype.handleLoginError = function (error, loginButton, loginButtonHtml) {
        error instanceof AjaxTimeoutError ?
            $(LoginForm.LOGIN_TIMEOUT_ERROR_CLASS).removeClass(LoginForm.DISPLAY_NONE_CLASS) :
            $(LoginForm.LOGIN_ERROR_CLASS).removeClass(LoginForm.DISPLAY_NONE_CLASS);
        if (error instanceof AjaxError) {
            this.showAjaxErrorMessage(error);
        }
        else if (typeof error === "string") {
            this.showStringErrorMessage(error);
        }
        loginButton.html(loginButtonHtml);
        this.loginSubject.next({ status: FormStatus.FAILED });
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: "login_failure",
            servletName: LoginForm.LOGIN_MODAL_TRACKING_SERVLET
        });
        doLogMessage("Login", error.toString());
        return EMPTY;
    };
    LoginForm.prototype.buildStream$ = function () {
        var _this = this;
        var buttonHtml = this.loginButton.html();
        return observableFromEvent(this.loginButton, "click")
            .pipe(tap(function () {
            if (_this.isWishlistModal()) {
                ClickStream.postClickStreamWithTrackingArguments({
                    pageAction: "click_login",
                    pageProperties: { module: "login" },
                    servletName: "product_detail",
                });
            }
            _this.hideErrorBanners();
        }), filter(function () { return _this.validateForm(); }), exhaustMap(function () { return _this.buildAjaxPost$()
            .pipe(catchError(function (error) {
            return _this.handleLoginError(error, _this.loginButton, buttonHtml);
        })); }));
    };
    LoginForm.prototype.buildPartnerLoginStream$ = function () {
        var _this = this;
        var buttonHtml = this.partnerLoginButton.html();
        return this.buildAjaxPost$()
            .pipe(catchError(function (error) {
            return _this.handleLoginError(error, _this.partnerLoginButton, buttonHtml);
        }));
    };
    LoginForm.prototype.subscribeToPartnerLoginButtonClick = function () {
        var _this = this;
        observableFromEvent(this.partnerLoginButton, "click")
            .pipe(tap(function () { return _this.hideErrorBanners(); }), filter(function () { return _this.validateForm(); }), exhaustMap(function () { return _this.buildPartnerLoginStream$(); }))
            .subscribe(function (response) { return _this.postClickStreamLoginSuccess(response); });
    };
    LoginForm.prototype.showAjaxErrorMessage = function (error) {
        if (error.status === HttpCodes.UNAUTHORIZED) {
            var loginError = error.response || LOGIN_ERROR.BAD_CREDENTIAL;
            this.hideLoginErrorMessages();
            this.displaySpecificErrorMessage(loginError);
        }
    };
    LoginForm.prototype.showStringErrorMessage = function (error) {
        var loginError = error || LOGIN_ERROR.BAD_CREDENTIAL;
        this.hideLoginErrorMessages();
        this.displaySpecificErrorMessage(loginError);
    };
    LoginForm.prototype.displaySpecificErrorMessage = function (error) {
        if ($("[data-error-type=\"" + error + "\"]").length) {
            $("[data-error-type=\"" + error + "\"]").removeClass(LoginForm.DISPLAY_NONE_CLASS);
        }
        else {
            $("[data-error-type=\"" + LOGIN_ERROR.BAD_CREDENTIAL + "\"]").removeClass(LoginForm.DISPLAY_NONE_CLASS);
        }
    };
    LoginForm.prototype.addLoadingBubbles = function () {
        this.loginButton.find(".login-button-text").addClass(LoginForm.DISPLAY_NONE_CLASS);
        this.loginButton.find(".loading-bubbles").removeClass(LoginForm.DISPLAY_NONE_CLASS);
        this.partnerLoginButton.find(".login-button-text").addClass(LoginForm.DISPLAY_NONE_CLASS);
        this.partnerLoginButton.find(".loading-bubbles").removeClass(LoginForm.DISPLAY_NONE_CLASS);
    };
    LoginForm.prototype.hideErrorBanners = function () {
        this.loginForm.parents(LoginForm.LOGIN_ERROR_CLASS).addClass(LoginForm.DISPLAY_NONE_CLASS);
        this.loginForm.parents(LoginForm.LOGIN_TIMEOUT_ERROR_CLASS).addClass(LoginForm.DISPLAY_NONE_CLASS);
    };
    LoginForm.prototype.hideLoginErrorMessages = function () {
        $("[data-error-type=\"" + LOGIN_ERROR.AGENT_ROLE_MISSING + "\"]").addClass(LoginForm.DISPLAY_NONE_CLASS);
        $("[data-error-type=\"" + LOGIN_ERROR.AFFILIATE_ROLE_MISSING + "\"]").addClass(LoginForm.DISPLAY_NONE_CLASS);
        $("[data-error-type=\"" + LOGIN_ERROR.BAD_CREDENTIAL + "\"]").addClass(LoginForm.DISPLAY_NONE_CLASS);
        $("[data-error-type=\"" + LOGIN_ERROR.USER_PROFILE_NOT_ACTIVE + "\"]").addClass(LoginForm.DISPLAY_NONE_CLASS);
        $("[data-error-type=\"" + LOGIN_ERROR.AGENT_ARCHIVED + "\"]").addClass(LoginForm.DISPLAY_NONE_CLASS);
        $("[data-error-type=\"" + LOGIN_ERROR.GENERIC_ERROR + "\"]").addClass(LoginForm.DISPLAY_NONE_CLASS);
    };
    LoginForm.LOGIN_FORM_CLASS = ".login-form";
    LoginForm.LOGIN_BUTTON_CLASS = ".login-button";
    LoginForm.PARTNER_LOGIN_BUTTON_CLASS = ".partner-login";
    LoginForm.LOGIN_ERROR_CLASS = ".login-error";
    LoginForm.LOGIN_TIMEOUT_ERROR_CLASS = ".login-timeout-error";
    LoginForm.DISPLAY_NONE_CLASS = "d-none";
    LoginForm.KEY_ENTER = "Enter";
    LoginForm.LOGIN_MODAL_TRACKING_SERVLET = "account_modal_login";
    return LoginForm;
}());
export { LoginForm };
