export var HttpCodes;
(function (HttpCodes) {
    HttpCodes[HttpCodes["OK"] = 200] = "OK";
    HttpCodes[HttpCodes["MOVED_PERMANENTLY"] = 301] = "MOVED_PERMANENTLY";
    HttpCodes[HttpCodes["FOUND"] = 302] = "FOUND";
    HttpCodes[HttpCodes["TEMPORARY_REDIRECT"] = 307] = "TEMPORARY_REDIRECT";
    HttpCodes[HttpCodes["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    HttpCodes[HttpCodes["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    HttpCodes[HttpCodes["FORBIDDEN"] = 403] = "FORBIDDEN";
    HttpCodes[HttpCodes["NOT_FOUND"] = 404] = "NOT_FOUND";
    HttpCodes[HttpCodes["I_AM_A_TEAPOT"] = 418] = "I_AM_A_TEAPOT";
    HttpCodes[HttpCodes["STATUS_UNPROCESSABLE_ENTITY"] = 422] = "STATUS_UNPROCESSABLE_ENTITY";
    HttpCodes[HttpCodes["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
    HttpCodes[HttpCodes["SERVICE_UNAVAILABLE"] = 503] = "SERVICE_UNAVAILABLE";
})(HttpCodes || (HttpCodes = {}));
