var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { firebaseAuth, OAUTH_PROVIDER } from "./firebaseAuth";
import { LOGIN_ERROR } from "../authUtil";
import { from } from "rxjs";
import { ClickStream } from "../../clickstream";
import { isDefinedAndNotNull } from "../../common/utils/objectUtil";
import { localStorage } from "../../common/storage";
var FirebaseAuthCommon = (function () {
    function FirebaseAuthCommon() {
    }
    FirebaseAuthCommon.isFirebaseInitialized = function () {
        return firebaseAuth !== null;
    };
    FirebaseAuthCommon.buildAjaxHeaders = function (idToken) {
        return { "Content-Type": "application/json", "X-Authorization": idToken };
    };
    FirebaseAuthCommon.buildSignInAjaxHeaders = function (idToken, refreshToken, authExpiry) {
        return { "Content-Type": "application/json", "X-Authorization": idToken, 'X-Refresh': refreshToken, 'X-Authorization-Expiry': authExpiry };
    };
    FirebaseAuthCommon.buildTokenUpdateHeaders = function (idToken, refreshToken, authExpiry) {
        return { "Content-Type": "application/json", "X-Authorization": idToken, 'X-Refresh': refreshToken, 'X-Authorization-Expiry': authExpiry };
    };
    FirebaseAuthCommon.parseFirebaseLogInError = function (loginError) {
        if (!loginError) {
            return LOGIN_ERROR.BAD_CREDENTIAL;
        }
        switch (loginError) {
            case "auth/custom-token-mismatch":
            case "auth/invalid-custom-token":
            case "auth/invalid-email":
            case "auth/user-not-found":
            case "auth/wrong-password":
                return LOGIN_ERROR.BAD_CREDENTIAL;
            case "auth/user-disabled":
                return LOGIN_ERROR.USER_PROFILE_NOT_ACTIVE;
            default:
                return LOGIN_ERROR.BAD_CREDENTIAL;
        }
    };
    FirebaseAuthCommon.reject = function (message) {
        return from(Promise.reject(message));
    };
    FirebaseAuthCommon.postClickStreamLoginTracking = function (authReason) {
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: "authenticated",
            pageProperties: { authReason: authReason },
            servletName: ClickStream.getServlet(),
        });
    };
    FirebaseAuthCommon.equalsIgnoreCase = function (a, b) {
        return isDefinedAndNotNull(a) && isDefinedAndNotNull(b) && a.toLowerCase() === b.toLowerCase();
    };
    FirebaseAuthCommon.linkPendingAccountAsync = function (user) {
        if (!user) {
            localStorage.remove(FirebaseAuthCommon.PENDING_CREDENTIALS_STORAGE_KEY);
            return;
        }
        var pendingCredential = localStorage.getObject(FirebaseAuthCommon.PENDING_CREDENTIALS_STORAGE_KEY);
        if (pendingCredential) {
            var options = pendingCredential.oauthAccessToken ?
                { accessToken: pendingCredential.oauthAccessToken } : __assign({}, pendingCredential);
            var provider = new OAUTH_PROVIDER(pendingCredential.providerId).credential(options);
            user.linkWithCredential(provider)
                .then(function (userCredential) {
                return userCredential && userCredential.user && userCredential.user.getIdToken(false);
            })
                .finally(function () { return localStorage.remove(FirebaseAuthCommon.PENDING_CREDENTIALS_STORAGE_KEY); });
        }
    };
    FirebaseAuthCommon.USER_ACCOUNT_MANAGEMENT_API = "/orion/ajax/userAccount";
    FirebaseAuthCommon.CSRF_STORAGE_KEY = "_csrf";
    FirebaseAuthCommon.SUBSCRIBE_STORAGE_KEY = "isSubscribe";
    FirebaseAuthCommon.PENDING_CREDENTIALS_STORAGE_KEY = "pendingAccountCredentials";
    FirebaseAuthCommon.PENDING_CREDENTIALS_STORAGE_TTL_MS = 600000;
    return FirebaseAuthCommon;
}());
export { FirebaseAuthCommon };
