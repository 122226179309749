import { fromEvent as observableFromEvent } from "rxjs";
import { doSocialLogin, SocialAuthProvider } from "./authUtil";
var SocialSignUp = (function () {
    function SocialSignUp() {
        this.$socialLoginWrapper = $("#socialLoginWrapper");
        this.$subscribeCheckbox = $("#subscribeCheckbox");
        this.setupFormSubmit();
    }
    SocialSignUp.prototype.setupFormSubmit = function () {
        var _this = this;
        observableFromEvent($(".social-login-button"), "click")
            .subscribe(function (event) {
            var csrfToken = _this.$socialLoginWrapper && _this.$socialLoginWrapper.find("input[name='_csrf']").val().toString();
            var subscribe = _this.$subscribeCheckbox && _this.$subscribeCheckbox.is(":checked");
            var provider = $(event.target).closest("[data-provider]").attr("data-provider");
            var socialAuthProvider = SocialAuthProvider[provider];
            doSocialLogin(socialAuthProvider, csrfToken, subscribe);
        });
    };
    return SocialSignUp;
}());
export { SocialSignUp };
