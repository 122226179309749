import "../../scss/show-hide-password.scss";
import { fromEvent as observableFromEvent } from "rxjs";
import $ from "../common/jquery";
var ShowHidePassword = (function () {
    function ShowHidePassword($showHideButton) {
        this.watchShowHide($showHideButton);
    }
    ShowHidePassword.prototype.watchShowHide = function ($parentElement) {
        observableFromEvent($parentElement, "click")
            .subscribe(function (event) {
            var $passwordShowHideButton = $(event.currentTarget);
            var $passwordInput = $passwordShowHideButton.closest(".show-hide-password")
                .find(".password-input input");
            if ($passwordInput.attr(ShowHidePassword.ATTR_TYPE) === ShowHidePassword.INPUT_TEXT) {
                $passwordInput.attr(ShowHidePassword.ATTR_TYPE, ShowHidePassword.INPUT_PASSWORD);
                $passwordShowHideButton.removeClass(ShowHidePassword.PASSWORD_VISIBLE_CLASS);
            }
            else if ($passwordInput.attr(ShowHidePassword.ATTR_TYPE) === ShowHidePassword.INPUT_PASSWORD) {
                $passwordInput.attr(ShowHidePassword.ATTR_TYPE, ShowHidePassword.INPUT_TEXT);
                $passwordShowHideButton.addClass(ShowHidePassword.PASSWORD_VISIBLE_CLASS);
            }
        });
    };
    ShowHidePassword.SHOW_HIDE_BTN_CLASS = ".show-hide-btn";
    ShowHidePassword.INPUT_TEXT = "text";
    ShowHidePassword.INPUT_PASSWORD = "password";
    ShowHidePassword.ATTR_TYPE = "type";
    ShowHidePassword.PASSWORD_VISIBLE_CLASS = "password-visible";
    return ShowHidePassword;
}());
export { ShowHidePassword };
