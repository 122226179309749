import "@firebase/polyfill";
import firebase from "firebase/app";
import "firebase/auth";
var config = window.firebaseClientConfig;
var firebaseApp = config && !firebase.apps.length ? firebase.initializeApp(config) : null;
var firebaseAuth = firebaseApp && firebaseApp.auth();
var EMAIL_AUTH_PROVIDER = firebase.auth.EmailAuthProvider;
var FACEBOOK_AUTH_PROVIDER = firebase.auth.FacebookAuthProvider;
var GOOGLE_AUTH_PROVIDER = firebase.auth.GoogleAuthProvider;
var OAUTH_PROVIDER = firebase.auth.OAuthProvider;
export { firebaseAuth, EMAIL_AUTH_PROVIDER, FACEBOOK_AUTH_PROVIDER, GOOGLE_AUTH_PROVIDER, OAUTH_PROVIDER };
