import { from, of } from "rxjs";
import { EMAIL_AUTH_PROVIDER, FACEBOOK_AUTH_PROVIDER, firebaseAuth, GOOGLE_AUTH_PROVIDER, OAUTH_PROVIDER } from "./firebaseAuth";
import { RESET_PASSWORD_CUSTOM_ERROR, SIGNUP_ERROR, SocialAuthProvider, UPDATE_EMAIL_RESULT, UPDATE_PASSWORD_RESULT } from "../authUtil";
import { AjaxService, HTML_RESPONSE_TYPE } from "../../common/ajax";
import { FirebaseAuthCommon } from "./firebaseAuthCommon";
import { FirebaseAuthProvider } from "./firebaseAuthProvider";
import { isDefinedAndNotNull } from "../../common/utils/objectUtil";
var FirebaseAuthHandler = (function () {
    function FirebaseAuthHandler(ajaxService) {
        if (ajaxService === void 0) { ajaxService = new AjaxService(); }
        this.mapSignInMethods = function (signinMethods) {
            return signinMethods
                .map(function (method) {
                switch (method) {
                    case EMAIL_AUTH_PROVIDER.EMAIL_PASSWORD_SIGN_IN_METHOD:
                        return FirebaseAuthProvider.PASSWORD;
                    case EMAIL_AUTH_PROVIDER.EMAIL_LINK_SIGN_IN_METHOD:
                        return FirebaseAuthProvider.MAGIC_LINK;
                    case FACEBOOK_AUTH_PROVIDER.FACEBOOK_SIGN_IN_METHOD:
                        return FirebaseAuthProvider.FACEBOOK;
                    case GOOGLE_AUTH_PROVIDER.GOOGLE_SIGN_IN_METHOD:
                        return FirebaseAuthProvider.GOOGLE;
                    case new OAUTH_PROVIDER(SocialAuthProvider.APPLE).providerId:
                        return FirebaseAuthProvider.APPLE;
                    default:
                        return null;
                }
            })
                .filter(function (val) { return isDefinedAndNotNull(val); });
        };
        this.ajaxService = ajaxService;
    }
    FirebaseAuthHandler.reauthenticateUserWithPassword = function (password) {
        var currentUser = firebaseAuth.currentUser;
        if (!firebaseAuth.currentUser) {
            return Promise.reject({ code: "auth/user-not-found" });
        }
        return currentUser.reauthenticateWithCredential(EMAIL_AUTH_PROVIDER.credential(currentUser.email, password))
            .then(function (userCredential) { return userCredential.user; });
    };
    FirebaseAuthHandler.saveDisplayName = function (user, firstName, lastName) {
        return user.updateProfile({ displayName: firstName + " " + lastName });
    };
    FirebaseAuthHandler.parseFirebaseSignUpError = function (signUpError) {
        if (!signUpError) {
            return SIGNUP_ERROR.UNKNOWN;
        }
        switch (signUpError) {
            case "auth/email-already-in-use":
                return SIGNUP_ERROR.EMAIL_EXISTS;
            case "auth/weak-password":
                return SIGNUP_ERROR.PASSWORD_INVALID;
            default:
                return SIGNUP_ERROR.UNKNOWN;
        }
    };
    FirebaseAuthHandler.parseFirebaseUpdatePasswordError = function (authError) {
        if (!authError) {
            return UPDATE_PASSWORD_RESULT.UNKNOWN;
        }
        switch (authError) {
            case "auth/wrong-password":
                return UPDATE_PASSWORD_RESULT.PASSWORD_INCORRECT;
            case "auth/weak-password":
                return UPDATE_PASSWORD_RESULT.PASSWORD_INVALID;
            case "auth/user-not-found":
            case "auth/requires-recent-login":
                return UPDATE_PASSWORD_RESULT.NO_SUCH_ACCOUNT;
            default:
                return UPDATE_PASSWORD_RESULT.UNKNOWN;
        }
    };
    FirebaseAuthHandler.parseFirebaseUpdateEmailError = function (authError) {
        if (!authError) {
            return UPDATE_EMAIL_RESULT.UNKNOWN;
        }
        switch (authError) {
            case "auth/email-already-in-use":
                return UPDATE_EMAIL_RESULT.ACCOUNT_EMAIL_ADDRESS_NOT_UNIQUE;
            case "auth/user-not-found":
            case "auth/requires-recent-login":
                return UPDATE_EMAIL_RESULT.NO_SUCH_ACCOUNT;
            case "auth/user-mismatch":
            case "auth/invalid-credential":
            case "auth/wrong-password":
                return UPDATE_EMAIL_RESULT.PASSWORD_INCORRECT;
            case "auth/invalid-email":
            default:
                return UPDATE_EMAIL_RESULT.UNKNOWN;
        }
    };
    FirebaseAuthHandler.parseFirebaseResetPasswordError = function (resetPasswordError) {
        if (!resetPasswordError) {
            return RESET_PASSWORD_CUSTOM_ERROR.UNKNOWN;
        }
        switch (resetPasswordError) {
            case "auth/expired-action-code":
            case "auth/invalid-action-code":
                return RESET_PASSWORD_CUSTOM_ERROR.TOKEN_INVALID;
            case "auth/weak-password":
                return RESET_PASSWORD_CUSTOM_ERROR.PASSWORD_INVALID;
            default:
                return RESET_PASSWORD_CUSTOM_ERROR.UNKNOWN;
        }
    };
    FirebaseAuthHandler.prototype.signupWithPassword = function (request, timeout) {
        var _this = this;
        if (!FirebaseAuthCommon.isFirebaseInitialized()) {
            return FirebaseAuthCommon.reject("Firebase Auth is not initialized for sign-up");
        }
        var firebasePromise = firebaseAuth.createUserWithEmailAndPassword(request.email, request.password)
            .then(function (userCredential) {
            return FirebaseAuthHandler.saveDisplayName(userCredential.user, request.firstName, request.lastName)
                .catch(function () { return Promise.resolve(); })
                .then(function () { return userCredential.user.getIdTokenResult(false)
                .then(function (idTokenResult) {
                var postBody = { subscribe: request.subscribe, password: request.password };
                var path = FirebaseAuthCommon.USER_ACCOUNT_MANAGEMENT_API + "/signup";
                var headers = FirebaseAuthCommon.buildSignInAjaxHeaders(idTokenResult.token, userCredential.user.refreshToken, idTokenResult.expirationTime);
                return _this.ajaxService.doPostWithTimeout(path, timeout, postBody, HTML_RESPONSE_TYPE, headers)
                    .toPromise()
                    .then(function (response) {
                    FirebaseAuthCommon.postClickStreamLoginTracking("Password");
                    return response;
                });
            }, function (error) { return Promise.reject(FirebaseAuthHandler.parseFirebaseSignUpError(error && error.code)); }); });
        });
        return from(firebasePromise);
    };
    FirebaseAuthHandler.prototype.signInWithPassword = function (email, password, timeout, csrf) {
        var _this = this;
        if (!FirebaseAuthCommon.isFirebaseInitialized()) {
            return FirebaseAuthCommon.reject("Firebase Auth is not initialized for password sign-in");
        }
        var firebasePromise = firebaseAuth.signInWithEmailAndPassword(email, password)
            .then(function (userCredential) {
            FirebaseAuthCommon.linkPendingAccountAsync(userCredential.user);
            return userCredential.user.getIdTokenResult(false)
                .then(function (idTokenResult) {
                var postBody = { password: password, _csrf: (csrf === null ? undefined : csrf) };
                var path = FirebaseAuthCommon.USER_ACCOUNT_MANAGEMENT_API + "/login";
                var headers = FirebaseAuthCommon.buildSignInAjaxHeaders(idTokenResult.token, userCredential.user.refreshToken, idTokenResult.expirationTime);
                return _this.ajaxService.doPostWithTimeout(path, timeout, postBody, HTML_RESPONSE_TYPE, headers)
                    .toPromise()
                    .then(function (response) {
                    FirebaseAuthCommon.postClickStreamLoginTracking("Password");
                    return response;
                });
            }, function (error) { return Promise.reject(FirebaseAuthCommon.parseFirebaseLogInError(error && error.code)); });
        });
        return from(firebasePromise);
    };
    FirebaseAuthHandler.prototype.signInWithCustomToken = function (customToken) {
        var _this = this;
        if (!FirebaseAuthCommon.isFirebaseInitialized()) {
            return FirebaseAuthCommon.reject("Firebase Auth is not initialized for custom token sign-in");
        }
        var firebasePromise = firebaseAuth.signInWithCustomToken(customToken)
            .then(function (userCredential) { return userCredential.user.getIdTokenResult(false)
            .then(function (idTokenResult) {
            var path = FirebaseAuthCommon.USER_ACCOUNT_MANAGEMENT_API + "/login";
            var headers = FirebaseAuthCommon.buildSignInAjaxHeaders(idTokenResult.token, userCredential.user.refreshToken, idTokenResult.expirationTime);
            return _this.ajaxService.doPost(path, {}, HTML_RESPONSE_TYPE, headers)
                .toPromise()
                .then(function (response) {
                FirebaseAuthCommon.postClickStreamLoginTracking("Password");
                return response;
            });
        }, function (error) { return Promise.reject(FirebaseAuthCommon.parseFirebaseLogInError(error && error.code)); }); });
        return from(firebasePromise);
    };
    FirebaseAuthHandler.prototype.signOut = function () {
        return FirebaseAuthCommon.isFirebaseInitialized() ? from(firebaseAuth.signOut()) : of(void (0));
    };
    FirebaseAuthHandler.prototype.addPassword = function (password) {
        if (!FirebaseAuthCommon.isFirebaseInitialized()) {
            return FirebaseAuthCommon.reject("Firebase Auth is not initialized for add password");
        }
        var firebasePromise = firebaseAuth.currentUser.updatePassword(password)
            .catch(function (error) { return Promise.reject(FirebaseAuthHandler.parseFirebaseUpdatePasswordError(error && error.code)); });
        return from(firebasePromise);
    };
    FirebaseAuthHandler.prototype.updatePassword = function (currentPassword, newPassword) {
        var _this = this;
        if (!FirebaseAuthCommon.isFirebaseInitialized()) {
            return FirebaseAuthCommon.reject("Firebase Auth is not initialized for update password");
        }
        var reauthenticatedUser = FirebaseAuthHandler.reauthenticateUserWithPassword(currentPassword);
        var updatedPassword = reauthenticatedUser.then(function (user) { return user.updatePassword(newPassword); });
        var firebasePromise = Promise.all([reauthenticatedUser, updatedPassword])
            .then(function (_a) {
            var user = _a[0];
            return user.getIdTokenResult(false)
                .then(function (idTokenResult) {
                var path = FirebaseAuthCommon.USER_ACCOUNT_MANAGEMENT_API + "/update/password";
                var headers = FirebaseAuthCommon.buildTokenUpdateHeaders(idTokenResult.token, user.refreshToken, idTokenResult.expirationTime);
                return _this.ajaxService.doPost(path, { newPassword: newPassword }, HTML_RESPONSE_TYPE, headers).toPromise();
            }, function (error) { return Promise.reject(FirebaseAuthHandler.parseFirebaseUpdatePasswordError(error && error.code)); });
        });
        return from(firebasePromise);
    };
    FirebaseAuthHandler.prototype.updateEmail = function (currentPassword, newEmail) {
        var _this = this;
        if (!FirebaseAuthCommon.isFirebaseInitialized()) {
            return FirebaseAuthCommon.reject("Firebase Auth is not initialized for update email");
        }
        var reauthenticatedUser = FirebaseAuthHandler.reauthenticateUserWithPassword(currentPassword);
        var updatedEmail = reauthenticatedUser.then(function (user) { return user.updateEmail(newEmail); });
        var firebasePromise = Promise.all([reauthenticatedUser, updatedEmail])
            .then(function (_a) {
            var user = _a[0];
            return user.getIdTokenResult(false)
                .then(function (idTokenResult) {
                var path = FirebaseAuthCommon.USER_ACCOUNT_MANAGEMENT_API + "/update/email";
                var headers = FirebaseAuthCommon.buildTokenUpdateHeaders(idTokenResult.token, user.refreshToken, idTokenResult.expirationTime);
                return _this.ajaxService.doPost(path, null, HTML_RESPONSE_TYPE, headers).toPromise();
            }, function (error) { return Promise.reject(FirebaseAuthHandler.parseFirebaseUpdateEmailError(error && error.code)); });
        });
        return from(firebasePromise);
    };
    FirebaseAuthHandler.prototype.confirmPasswordReset = function (oobCode, email, newPassword) {
        var _this = this;
        var firebasePromise = firebaseAuth.verifyPasswordResetCode(oobCode)
            .then(function (oobEmail) { return FirebaseAuthCommon.equalsIgnoreCase(email, oobEmail) ? Promise.resolve() : Promise.reject(); })
            .then(function () { return firebaseAuth.confirmPasswordReset(oobCode, newPassword); })
            .then(function () { return firebaseAuth.signInWithEmailAndPassword(email, newPassword); })
            .then(function (userCredential) { return userCredential && userCredential.user && userCredential.user.getIdToken(false); })
            .then(function (idToken) {
            var path = FirebaseAuthCommon.USER_ACCOUNT_MANAGEMENT_API + "/reset-password";
            var headers = FirebaseAuthCommon.buildAjaxHeaders(idToken);
            return _this.ajaxService.doPost(path, {}, HTML_RESPONSE_TYPE, headers).toPromise();
        }, function (error) { return Promise.reject(FirebaseAuthHandler.parseFirebaseResetPasswordError(error && error.code)); });
        return from(firebasePromise);
    };
    FirebaseAuthHandler.prototype.getProvidersForEmail = function (email) {
        var _this = this;
        return firebaseAuth.fetchSignInMethodsForEmail(email).then(function (providers) { return _this.mapSignInMethods(providers); });
    };
    FirebaseAuthHandler.prototype.logMessage = function (action, message) {
        var logRequest = {
            action: action,
            message: message,
            type: "ERROR",
            source: "DEFAULT_SIGNIN_SIGNUP"
        };
        var path = FirebaseAuthCommon.USER_ACCOUNT_MANAGEMENT_API + "/log";
        var headers = { "Content-Type": "application/json" };
        this.ajaxService.doPost(path, logRequest, HTML_RESPONSE_TYPE, headers).toPromise();
    };
    return FirebaseAuthHandler;
}());
export { FirebaseAuthHandler };
