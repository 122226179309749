var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SOCIAL_AUTH_REASON_MAP, SocialAuthProvider } from "../authUtil";
import { EMAIL_AUTH_PROVIDER, FACEBOOK_AUTH_PROVIDER, firebaseAuth, GOOGLE_AUTH_PROVIDER, OAUTH_PROVIDER, } from "./firebaseAuth";
import { AjaxService, HTML_RESPONSE_TYPE } from "../../common/ajax";
import { from } from "rxjs";
import { localStorage } from "../../common/storage";
import $ from "../../common/jquery";
import { FirebaseAuthCommon } from "./firebaseAuthCommon";
import { EmailSubscription } from "../../EmailSubscription";
import { LoginModalSuccessHandler } from "../handler/loginModalSuccessHandler";
import { AccountModalLoader } from "../accountModalLoader";
import { LoginForm } from "../loginForm";
import "firebase/auth";
var SOCIAL_LOGIN_ERROR = {
    ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_APPLE: "ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_APPLE",
    ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_FACEBOOK: "ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_FACEBOOK",
    ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_GOOGLE: "ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_GOOGLE",
    ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_PASSWORD: "ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_PASSWORD",
    WEB_STORAGE_UNSUPPORTED: "WEB_STORAGE_UNSUPPORTED",
    UNKNOWN: "UNKNOWN",
};
var FirebaseSocialAuthHandler = (function () {
    function FirebaseSocialAuthHandler(ajaxService) {
        if (ajaxService === void 0) { ajaxService = new AjaxService(); }
        this.ajaxService = ajaxService;
        this.emailSubscription = new EmailSubscription(window, $("#emailSubscriptionOptIn"));
    }
    FirebaseSocialAuthHandler.getFirebaseSocialAuthProviderInstance = function (socialAuthProvider) {
        if (!socialAuthProvider) {
            throw new Error("Social auth provider invalid");
        }
        switch (socialAuthProvider) {
            case SocialAuthProvider.APPLE:
                return new OAUTH_PROVIDER(socialAuthProvider);
            case SocialAuthProvider.FACEBOOK:
                return new FACEBOOK_AUTH_PROVIDER();
            case SocialAuthProvider.GOOGLE:
                return new GOOGLE_AUTH_PROVIDER();
            default:
                throw new Error("Social auth provider not recognized");
        }
    };
    FirebaseSocialAuthHandler.prototype.signInWithSocial = function (socialAuthProvider, csrf, isSubscribe) {
        if (!FirebaseAuthCommon.isFirebaseInitialized()) {
            return FirebaseAuthCommon.reject("Firebase Auth is not initialized for social sign-in");
        }
        csrf && localStorage.set(FirebaseAuthCommon.CSRF_STORAGE_KEY, csrf);
        isSubscribe &&
            localStorage.set(FirebaseAuthCommon.SUBSCRIBE_STORAGE_KEY, "true");
        var provider = FirebaseSocialAuthHandler.getFirebaseSocialAuthProviderInstance(socialAuthProvider);
        firebaseAuth.useDeviceLanguage();
        return from(firebaseAuth.signInWithRedirect(provider));
    };
    FirebaseSocialAuthHandler.prototype.handleRedirectResult = function () {
        var _this = this;
        if (!firebaseAuth) {
            return FirebaseAuthCommon.reject("Firebase Auth is not initialized to handle redirects");
        }
        var redirectResult = firebaseAuth.getRedirectResult();
        var idTokenResult = redirectResult.then(function (userCredential) {
            FirebaseAuthCommon.linkPendingAccountAsync(userCredential.user);
            return userCredential.user && userCredential.user.getIdTokenResult(false);
        });
        var isNewAccount = redirectResult.then(function (userCredential) {
            return userCredential.additionalUserInfo &&
                userCredential.additionalUserInfo.isNewUser;
        });
        var provider = redirectResult.then(function (userCredential) {
            return userCredential.credential && userCredential.credential.providerId;
        });
        var refreshToken = redirectResult.then(function (userCredential) { return userCredential.user && userCredential.user.refreshToken; });
        var firebasePromise = Promise.all([idTokenResult, refreshToken, isNewAccount, provider])
            .then(function (_a) {
            var idTokenResult = _a[0], refreshToken = _a[1], isNewAccount = _a[2], socialProvider = _a[3];
            if (idTokenResult && idTokenResult.token) {
                var provider_1 = Object.keys(SocialAuthProvider)
                    .filter(function (key) {
                    return SocialAuthProvider[key] === socialProvider;
                })
                    .pop();
                if (isNewAccount) {
                    _this.emailSubscription.startEmailSubscriptionModalVerification();
                    return _this.completeSocialSignUp(idTokenResult.token, refreshToken, idTokenResult.expirationTime, provider_1);
                }
                return _this.completeSocialSignIn(idTokenResult.token, refreshToken, idTokenResult.expirationTime, provider_1);
            }
        }, function (error) { return _this.handleSocialLoginError(error); })
            .finally(function () {
            localStorage.remove(FirebaseAuthCommon.CSRF_STORAGE_KEY);
            localStorage.remove(FirebaseAuthCommon.SUBSCRIBE_STORAGE_KEY);
        });
        return from(firebasePromise);
    };
    FirebaseSocialAuthHandler.prototype.signInOrSignUpWithGoogleOneTapToken = function (googleOneTapIdToken, isSubscribe) {
        var _this = this;
        return firebaseAuth
            .signInWithCredential(GOOGLE_AUTH_PROVIDER.credential(googleOneTapIdToken))
            .then(function (userCredential) {
            return userCredential.user
                .getIdTokenResult(false)
                .then(function (result) { return ({
                isNewUser: userCredential.additionalUserInfo.isNewUser,
                idToken: result.token,
                refreshToken: userCredential.user.refreshToken,
                authExpiry: result.expirationTime
            }); });
        })
            .then(function (_a) {
            var idToken = _a.idToken, isNewUser = _a.isNewUser, refreshToken = _a.refreshToken, authExpiry = _a.authExpiry;
            if (isNewUser) {
                isSubscribe && localStorage.set(FirebaseAuthCommon.SUBSCRIBE_STORAGE_KEY, "true");
                return _this.completeSocialSignUp(idToken, refreshToken, authExpiry, "GOOGLE_ONE_TAP");
            }
            return _this.completeSocialSignIn(idToken, refreshToken, authExpiry, "GOOGLE_ONE_TAP");
        })
            .catch(function (error) {
            return Promise.reject(FirebaseAuthCommon.parseFirebaseLogInError(error && error.code));
        })
            .finally(function () {
            return localStorage.remove(FirebaseAuthCommon.SUBSCRIBE_STORAGE_KEY);
        });
    };
    FirebaseSocialAuthHandler.prototype.completeSocialSignUp = function (idToken, refreshToken, authExpiry, provider) {
        var _this = this;
        var subscribe = localStorage.get(FirebaseAuthCommon.SUBSCRIBE_STORAGE_KEY) === "true";
        var path = FirebaseAuthCommon.USER_ACCOUNT_MANAGEMENT_API + "/social-signup";
        var postBody = { provider: provider, subscribe: subscribe };
        return this.postSocialLoginOrSignUpRequest(path, postBody, idToken, refreshToken, authExpiry, provider).then(function (response) {
            _this.emailSubscription.renderModalContentAfterAccountCreation(provider);
            return response;
        });
    };
    FirebaseSocialAuthHandler.prototype.completeSocialSignIn = function (idToken, refreshToken, authExpiry, provider) {
        var csrf = localStorage.get(FirebaseAuthCommon.CSRF_STORAGE_KEY);
        var path = FirebaseAuthCommon.USER_ACCOUNT_MANAGEMENT_API + "/login";
        var postBody = __assign({ provider: provider }, (csrf && { _csrf: csrf }));
        return this.postSocialLoginOrSignUpRequest(path, postBody, idToken, refreshToken, authExpiry, provider).then(function (response) {
            window.location.reload();
            return response;
        });
    };
    FirebaseSocialAuthHandler.prototype.postSocialLoginOrSignUpRequest = function (path, body, idToken, refreshToken, authExpiry, provider) {
        var headers = FirebaseAuthCommon.buildSignInAjaxHeaders(idToken, refreshToken, authExpiry);
        return this.ajaxService
            .doPost(path, body, HTML_RESPONSE_TYPE, headers)
            .toPromise()
            .then(function (response) {
            var authReason = SOCIAL_AUTH_REASON_MAP[provider];
            FirebaseAuthCommon.postClickStreamLoginTracking(authReason);
            return response;
        });
    };
    FirebaseSocialAuthHandler.prototype.handleSocialLoginError = function (error) {
        var _this = this;
        var isOnLoginSignupPage = window.location.pathname.includes("/login") ||
            window.location.pathname.includes("/signup");
        var ajaxObservable = from(Promise.resolve());
        if (!isOnLoginSignupPage) {
            var $loginModal = $("#loginModalContainer");
            if ($loginModal.length > 0) {
                var loginForm = new LoginForm($loginModal);
                ajaxObservable = AccountModalLoader.loadAccountModalContent("login", $loginModal, new LoginModalSuccessHandler(), loginForm);
            }
        }
        ajaxObservable.subscribe(function () {
            var $errorWrapper = $(".social-login-error");
            if ($errorWrapper.length > 0) {
                _this.parseFirebaseSocialLoginError(error).then(function (errorType) {
                    $("[data-error-type=\"" + errorType + "\"]").removeClass("d-none");
                    $errorWrapper.removeClass("d-none");
                    if (errorType.startsWith("ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL")) {
                        localStorage.setObject(FirebaseAuthCommon.PENDING_CREDENTIALS_STORAGE_KEY, error.credential, FirebaseAuthCommon.PENDING_CREDENTIALS_STORAGE_TTL_MS);
                    }
                });
            }
        });
        return Promise.reject(error);
    };
    FirebaseSocialAuthHandler.prototype.parseFirebaseSocialLoginError = function (error) {
        if (error) {
            if (error.code === "auth/account-exists-with-different-credential" &&
                error.email) {
                return firebaseAuth
                    .fetchSignInMethodsForEmail(error.email)
                    .then(function (methods) {
                    if (!methods) {
                        return SOCIAL_LOGIN_ERROR.UNKNOWN;
                    }
                    switch (methods[0]) {
                        case EMAIL_AUTH_PROVIDER.EMAIL_PASSWORD_SIGN_IN_METHOD:
                            return SOCIAL_LOGIN_ERROR.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_PASSWORD;
                        case FACEBOOK_AUTH_PROVIDER.FACEBOOK_SIGN_IN_METHOD:
                            return SOCIAL_LOGIN_ERROR.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_FACEBOOK;
                        case GOOGLE_AUTH_PROVIDER.GOOGLE_SIGN_IN_METHOD:
                            return SOCIAL_LOGIN_ERROR.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_GOOGLE;
                        case new OAUTH_PROVIDER(SocialAuthProvider.APPLE).providerId:
                            return SOCIAL_LOGIN_ERROR.ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_APPLE;
                        default:
                            return SOCIAL_LOGIN_ERROR.UNKNOWN;
                    }
                });
            }
            if (error.code === "auth/web-storage-unsupported") {
                return Promise.resolve(SOCIAL_LOGIN_ERROR.WEB_STORAGE_UNSUPPORTED);
            }
        }
        return Promise.resolve(SOCIAL_LOGIN_ERROR.UNKNOWN);
    };
    return FirebaseSocialAuthHandler;
}());
export { FirebaseSocialAuthHandler };
