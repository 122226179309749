import { LoginSignupModal } from "./loginSignupModal";
import { AjaxService } from "../common/ajax";
import { from, fromEvent as observableFromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import { SocialSignUp } from "./socialSignUp";
import { ClickStream } from "../clickstream";
import $ from "../common/jquery";
var AccountModalLoader = (function () {
    function AccountModalLoader($modalContainer, activationElementSelector, modalName, successHandler, form) {
        observableFromEvent($(document), "click")
            .pipe(filter(function (e) { return $(e.target).closest(activationElementSelector).length > 0; }))
            .subscribe(function () {
            AccountModalLoader.loadAccountModalContent(modalName, $modalContainer, successHandler, form);
        });
    }
    AccountModalLoader.loadAccountModalContent = function (modalName, modalContainer, successHandler, form) {
        var modalSelector = "#" + modalName + "Modal";
        var modalContent = modalContainer.find(modalSelector);
        if (modalContent.length) {
            modalContent.modal().on("shown.bs.modal", function () { return $("body").addClass("modal-open"); });
            return;
        }
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: "show_account_modal_login",
            servletName: ClickStream.getServlet()
        });
        var spinnerContainer = modalContainer.find(AccountModalLoader.ACCOUNT_SPINNER_SELECTOR);
        spinnerContainer.removeClass("d-none");
        var ajaxUrl = AccountModalLoader.MODAL_ENDPOINT_BASE_ADDRESS + modalName;
        var ajaxPromise = AccountModalLoader.ajaxService.doGet(ajaxUrl)
            .toPromise()
            .then(function (response) {
            modalContainer.append(response.response);
            new LoginSignupModal(modalContainer, form.initialize(), successHandler);
            spinnerContainer.addClass("d-none");
            modalContent = modalContainer.find(modalSelector);
            modalContent.modal();
            new SocialSignUp();
        });
        return from(ajaxPromise);
    };
    AccountModalLoader.MODAL_ENDPOINT_BASE_ADDRESS = "/orion/ajax/account-modal/";
    AccountModalLoader.ACCOUNT_SPINNER_SELECTOR = ".account-spinner-container";
    AccountModalLoader.ajaxService = new AjaxService();
    return AccountModalLoader;
}());
export { AccountModalLoader };
