import { fromEvent as observableFromEvent } from "rxjs";
import { FormStatus } from "./IFormResponse";
import { ClickStream } from "../clickstream";
var LoginSignupModal = (function () {
    function LoginSignupModal($modal, form, successHandler, window$) {
        if (window$ === void 0) { window$ = window; }
        this.$modal = $modal;
        this.form = form;
        this.successHandler = successHandler;
        this.window = window$;
        this.initialize();
        new ClickStream().bootstrap();
    }
    LoginSignupModal.restoreModalInteractions = function (modalInteractionsSubscription) {
        modalInteractionsSubscription.unsubscribe();
    };
    LoginSignupModal.prototype.initialize = function () {
        var _this = this;
        this.form.getFormObservable().subscribe(function (response) {
            if (response.status === FormStatus.SUBMITTED) {
                _this.modalInteractionsSubscription = _this.disableModalInteractions();
                _this.$modal.find("#closeButton").addClass("d-none");
            }
            else if (response.status === FormStatus.FAILED) {
                LoginSignupModal.restoreModalInteractions(_this.modalInteractionsSubscription);
                _this.$modal.find("#closeButton").removeClass("d-none");
            }
            else if (response.status === FormStatus.SUCCESS) {
                LoginSignupModal.restoreModalInteractions(_this.modalInteractionsSubscription);
                _this.successHandler.handleSuccess(response.message);
            }
        });
    };
    LoginSignupModal.prototype.disableModalInteractions = function () {
        return observableFromEvent(this.$modal, "hide.bs.modal")
            .subscribe(function (event) { return event.preventDefault(); });
    };
    return LoginSignupModal;
}());
export { LoginSignupModal };
